<script>
import NativeCampaignManagementNewView from './NativeCampaignManagementNewView'
import CoreApi from '@/api/core'
import NotifyService from '@/services/NotifyService'
import DamFilter from '@/model/DamFilter'

export default {
  name: 'NativeCampaignManagementFromArticleView',
  extends: NativeCampaignManagementNewView,
  methods: {
    async getArticleUrl (article) {
      const mainRoute = article.routes?.find(route => Boolean(route.isMain))
      if (mainRoute) {
        return mainRoute.absoluteUrl
      }
      const suggestUrl = `/article/${article.id}/route/suggest`
      return (await CoreApi().get(suggestUrl))?.data
    },
    async setCampaignFromArticle (articleId) {
      try {
        this.$store.commit('TOGGLE_LOADING', true)
        await this.$store.dispatch('article/fetchOne', articleId)
        const article = this.$store.getters['article/detail']
        this.nativeCampaign.mainAlternative.title = article.field.title
        this.nativeCampaign.mainAlternative.url = await this.getArticleUrl(article)
        const damId = article.expanded?.heroImage?.damMediaEmbed?.damId
        if (damId) {
          this.damSearchDefaultFilter = this._.cloneDeep(DamFilter)
          this.damSearchDefaultFilter.entityUuids = article.expanded?.heroImage?.damMediaEmbed?.damId
        } else {
          this.damSearchDefaultFilter = null
        }
      } catch (error) {
        console.error(error)
        NotifyService.setErrorMessage(error)
      } finally {
        this.$store.commit('TOGGLE_LOADING', false)
      }
    }
  },
  async created () {
    const fromArticleId = this.$route.params.id
    if (fromArticleId) {
      await this.setCampaignFromArticle(fromArticleId)
    }
  }
}
</script>
